.wrapper {
  position: absolute;
  width: clamp(200px, 400px, 100%);
  overflow: hidden;
  padding: 0;
  text-align: left;
  max-width: unset !important;
  font-family: var(--body-font);

  &:global {
    padding: 0;
    animation: fadeIn 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    position: absolute !important;
    top: unset !important;
    right: 0 !important;
    bottom: 12dvh !important;
    left: 0 !important;
    transform: none !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 300px;
    height: 250px;
  }
  // transition: all 0.3s ease-in-out;

  &:global(.mapboxgl-popup) {
    padding: 0;

    div:global(.mapboxgl-popup-content) {
      height: 100%;
      padding: 0;
      border: none;
      transition: opacity 0.3s ease-in-out;
      &:global {
        padding: 0;
      }

      div:first-of-type {
        height: 100%;
      }
      &:hover {
        opacity: 0.9;
      }
    }
    div:global(.mapboxgl-popup-tip) {
      display: none;
    }

    .content {
      display: flex;
      min-height: 140px;
      height: 100%;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
        height: 100%;
      }
      .info {
        display: flex;
        flex-direction: column;
        width: 50%;
        min-height: 140px;
        height: 100%;
        padding: var(--spacing-xs);

        @media (max-width: 768px) {
          width: 100%;
          flex: 1 1 auto;
          height: 50%;
        }
        .title {
          font-size: var(--font-size-sm);
          font-weight: 500;
          // font-family: "var(--heading-font)";
        }
        .description {
          line-height: 1.2em;
          color: var(--dark-text);
          margin: var(--spacing-xxs) 0;
        }
      }

      .imageWrapper {
        position: relative;
        width: 50%;
        height: 100%;
        height: 140px;
        @media (max-width: 768px) {
          width: auto;
          flex: 1 1 auto;
          // height: 50%;
          height: 100%;
        }
        img {
          object-fit: cover;
        }
        .image {
          position: relative;
          max-width: 100%;
        }
        .tagWrapper {
          position: absolute;
          bottom: 10px;
          right: 0;
        }
        .closeButton {
          position: absolute;
          top: var(--spacing-xs);
          right: var(--spacing-xs);
          padding: var(--spacing-xxs) var(--spacing-xs);
          border: none;
          border-radius: var(--border-radius-sm);
          background: var(--dark-background-translucent);
          font-size: var(--font-size-md);
          color: var(--light-text);
          z-index: 100;
        }
      }
    }
  }
}
