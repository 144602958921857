.locationTag {
  grid-area: location;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  color: var(--accent-1);
  background: var(--light-background-2);

  .locationIcon {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .countryName {
    // display: inline-flex;
    align-items: center;
  }
}
