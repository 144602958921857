.tag {
  width: max-content;
  margin-right: var(--spacing-xs);
  padding: 0.7em;
  border-radius: var(--border-radius-md);
  font-size: 0.9rem;
  color: var(--light-text);
  text-transform: capitalize;

  &.noTextTransform {
    text-transform: none;
  }

  &.smSize {
    padding: 0.25em 0.35em;
  }
  &.mdSize {
    padding: 0.5em 0.65em;
  }
  &.lgSize {
    padding: 0.75em 0.9em;
  }
  &.linkTag:hover {
    background: var(--accent-1);
    opacity: 0.7;
  }
  &.darkColor {
    background: var(--dark-background);
    opacity: 0.9;
  }
  &.lightColor {
    background: var(--light-background);
    opacity: 0.9;
  }
  &.transparentDarkColor {
    border: 1px solid var(--dark-background);
    background: transparent;
    opacity: 0.9;
    color: var(--dark-text);
  }
  &.transparentLightColor {
    border: 1px solid var(--light-background);
    opacity: 0.9;
    background: transparent;
    color: var(--light-text);
  }
}
