.otherInfoContainer {
  height: auto !important;
  margin-top: auto;
  margin-bottom: var(--spacing-xs);
  color: var(--dark-text);
  // max-height: 30%;
  .region {
    position: relative;
    height: auto !important;
  }
  .currency {
    font-size: var(--font-size-sm);
    font-weight: 500;
  }
}
